import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';

import { getFilteredTransactionIntervals } from 'jsx/lib/lookupAttributes';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormIntervalDatePicker from '../../../core/form/components/FormIntervalDatePicker';
import FormBase from '../../../core/form/components/FormBase';
import {
  initControls,
  saveControls,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { fetchAttributes } from '../actions/attributes';

import { controls as rainfallControls } from '../forms/propertyRainfalls';

import {
  fetchPropertyRainfall,
  createPropertyRainfall,
  updatePropertyRainfall,
  removePropertyRainfall,
} from '../actions/propertyRainfalls';

class PropertyRainfallsModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(rainfallControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Property Rainfalls',
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const controls = initControls(cloneDeep(rainfallControls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Rainfall',
      };
      this.props.dispatch(fetchAttributes({ type: 'transaction_intervals' }));

      if (this.props.id) {
        const { id } = this.props;
        updatedState = { ...updatedState, id, isNew: false, title: 'Edit Rainfall' };

        this.props.dispatch(fetchPropertyRainfall(id)).then((data) => {
          this.setState({
            data,
            controls: updateControls(controls, data),
          });
        });
      }

      this.setState(updatedState);
    }
  }

  onChange(event) {
    this.handleChange(event);
  }

  async onSave() {
    let { data } = this.state;
    const { controls } = this.state;
    const { isNew } = this.state;
    data = saveControls(controls, data);

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      let success;

      if (isNew) {
        delete data.id;
        success = await this.props.dispatch(createPropertyRainfall(data));
      } else {
        success = await this.props.dispatch(updatePropertyRainfall(data));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm('Removing rainfall data permanently. Continue?');
    if (confirmed) {
      const success = await this.props.dispatch(removePropertyRainfall(data.id));
      if (success) this.onClose(true);
    }
  }

  render() {
    const { controls, title, isNew } = this.state;
    const { isOpen } = this.props;
    const { transaction_intervals, responseMessage } = this.props.attributes;
    const iconName = 'clipboard-list';
    const filteredTransactionIntervals = getFilteredTransactionIntervals(transaction_intervals);

    if (!controls.property_id?.value || controls.property_id?.value === null) {
      controls.property_id.value = this.props.property.id;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormIntervalDatePicker
              handleChange={this.handleChange}
              controls={controls}
              intervals={filteredTransactionIntervals}
              intervalKey="transaction_interval_id"
              dateKey="transaction_date"
            />
            <FormInput handleChange={this.handleChange} control={controls.rainfall_mm} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises }) => ({
  attributes,
  enterprises,
});

export default connect(mapStoreToProps)(PropertyRainfallsModal);
