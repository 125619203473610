/* eslint-disable no-param-reassign */
import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Nav, TabContent, TabPane } from 'reactstrap';
import FormBase from 'jsx/components/core/form/components/FormBase';
import GenericModal from 'jsx/components/core/form/components/GenericModal';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { getFilteredTransactionIntervals } from 'jsx/lib/lookupAttributes';

import FormTab from '../../../core/form/components/FormTab';
import PageTitle from '../../../core/form/components/PageTitle';
import OffFarmToolbar from '../components/offfarm/OffFarmToolbar';
import GenericLsv from '../../../core/form/components/GenericLsv';
import { fetchAttributes } from '../actions/attributes';
import { fetchEnterpriseRanges } from '../actions/enterprises';
import { controls as controlsOffFarm } from '../forms/offFarm';
import {
  createOffFarmIncome,
  updateOffFarmIncome,
  fetchOffFarmIncomes,
  fetchOffFarmIncome,
  removeOffFarmIncome,
} from '../actions/offFarm';

class OffFarm extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      modalType: null,
      isModalOpen: false,
      modalData: null,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidUpdate() {
    const { isActive, forceRefresh, setRefresh } = this.props;

    if (isActive && forceRefresh && setRefresh) {
      this.onRefresh();
      if (setRefresh) setRefresh(false);
    }
  }

  async toggleTab(tab) {
    let { modalType } = this.state;
    const { selectedRanges } = this.props.enterprises;
    const { dispatch } = this.props;

    dispatch(fetchEnterpriseRanges());

    switch (tab) {
      case 'income': {
        await dispatch(fetchAttributes({ type: 'off_farm_income_types' }));
        await dispatch(fetchOffFarmIncomes({ ...selectedRanges }));
        modalType = 'off_farm_income';
        break;
      }

      default:
        break;
    }

    const { activeOffFarmTab } = this.props.off_farm_incomes;

    if (activeOffFarmTab !== tab) {
      dispatch({ type: 'SET_OFF_FARM_ACTIVE_TAB', payload: tab });
    }

    this.setState({
      modalType,
    });
  }

  onSave(data, isNew) {
    const { modalType } = this.state;
    if (isNew) delete data.id;
    let saveMethod;
    switch (modalType) {
      case 'off_farm_income': {
        saveMethod = isNew ? createOffFarmIncome : updateOffFarmIncome;
        break;
      }
      case 'off_farm_investment': {
        break;
      }
      default: {
        break;
      }
    }

    return this.props.dispatch(saveMethod(data));
  }

  onRefresh() {
    const { activeOffFarmTab } = this.props.off_farm_incomes;
    const tab_id = activeOffFarmTab;
    if (tab_id) this.toggleTab(tab_id);
  }

  onRemove(id) {
    const { modalType } = this.state;

    switch (modalType) {
      case 'off_farm_income': {
        return this.props.dispatch(removeOffFarmIncome(id));
      }
      case 'off_farm_investment':
      default:
        return null;
    }
  }

  setModalOptions(type) {
    const { transaction_intervals, off_farm_income_types } = this.props.attributes;
    const filteredTransactionIntervals = getFilteredTransactionIntervals(transaction_intervals);
    let options;

    switch (type) {
      case 'off_farm_investments': {
        break;
      }
      default: {
        const updatedControls = cloneDeep(controlsOffFarm);
        options = {
          title: 'Income',
          iconName: 'handshake',
          controls: updatedControls,
          options: {
            transaction_interval_id: filteredTransactionIntervals,
            type_id: off_farm_income_types,
          },
        };
        break;
      }
    }

    options.title = `Off Farm ${options.title}`;
    return options;
  }

  async setModal(isModalOpen, modalType = null, modalId = null) {
    let modalData;
    if (isModalOpen) {
      switch (modalType) {
        case 'off_farm_income': {
          if (modalId) modalData = await this.props.dispatch(fetchOffFarmIncome(modalId));
          break;
        }
        case 'off_farm_investments': {
          break;
        }
        default: {
          break;
        }
      }
    }

    const state = { isModalOpen, modalData };
    if (modalType) state.modalType = modalType;

    this.setState(state);
  }

  render() {
    const { id, modalType, isModalOpen, modalData } = this.state;
    const { activeOffFarmTab, responseMessage, off_farm_incomes } = this.props.off_farm_incomes;
    const { rows } = off_farm_incomes;
    const modalOptions = this.setModalOptions(modalType);
    const title = 'Off Farm Income';
    const iconName = 'handshake';

    return (
      <div className="p-0 h-100">
        <PageTitle title={title} iconName={iconName} />
        <OffFarmToolbar setModal={this.setModal} onRefresh={this.onRefresh} />

        <GenericModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          onRefresh={this.onRefresh}
          onClose={this.onRefresh}
          setModal={this.setModal}
          onSave={this.onSave}
          data={modalData}
          id={id}
          isOpen={isModalOpen}
          isNew={id === null}
          responseMessage={responseMessage}
          onRemove={this.onRemove}
        />

        <Nav tabs className="mt-2">
          <FormTab
            caption="Income"
            tabId="income"
            activeTab={activeOffFarmTab}
            toggle={this.toggleTab}
          />
        </Nav>

        <TabContent activeTab={activeOffFarmTab}>
          <TabPane tabId="income" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No Off Farm Income found"
              onClick={(incomeId) => {
                this.setModal(true, 'off_farm_income', incomeId);
              }}
              rows={rows}
              totalFormattingRules={{ includeDollarSign: true, includeCommas: true }}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises, off_farm_incomes }) => ({
  attributes,
  enterprises,
  off_farm_incomes,
});

export default connect(mapStoreToProps)(withContainerError(OffFarm));
