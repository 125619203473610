/* eslint-disable no-alert */
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { getFilteredTransactionIntervals } from 'jsx/lib/lookupAttributes';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import FormIntervalDatePicker from '../../../core/form/components/FormIntervalDatePicker';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';

import { controls as overheadControls } from '../forms/overheads';

import OverheadDistributionsLsv from '../components/OverheadDistributionsLsv';

import { fetchAttributes } from '../actions/attributes';
import { fetchEnterpriseDistributions } from '../actions/enterprises';

import {
  fetchOverhead,
  updateOverhead,
  createOverhead,
  removeOverhead,
} from '../actions/overheads';
import { fetchProperties } from '../../projects/actions/properties';

class OverheadModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(overheadControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Overhead',
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.loadAttributes = this.loadAttributes.bind(this);
    this.onDistributionChange = this.onDistributionChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      let controls = cloneDeep(initControls(overheadControls));
      controls.distributions.value = [];

      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Overhead',
      };

      await this.props.dispatch(fetchAttributes({ type: 'overhead_groups' }));
      await this.props.dispatch(fetchEnterpriseDistributions({ type: 'division' }));
      await this.props.dispatch(fetchProperties());

      if (this.props.id) {
        const { id } = this.props;

        // Fetch existing overhead
        const overhead = await this.props.dispatch(fetchOverhead(id));
        controls = updateControls(controls, overhead);

        const { parent_id } = overhead.type;
        if (parent_id) {
          await this.loadAttributes(parent_id);
          controls.group_id.value = parent_id;
        }

        updatedState = {
          ...updatedState,
          controls,
          data: overhead,
          id,
          isNew: false,
          title: 'Edit Overhead',
        };
      }

      this.setState(updatedState);
    }
  }

  async onSave(closeModal = true) {
    let { data } = this.state;
    const { controls, isNew } = this.state;

    data = saveControls(controls, data);

    // Convert distribution_pcnt into decimal form
    data.distributions = controls.distributions.value.map((distribution) => {
      const distribution_pcnt = distribution.distribution_pcnt / 100;
      return {
        ...distribution,
        distribution_pcnt,
      };
    });

    if (data.distributions) {
      data.distributions.map((row) => {
        const updatedRow = { ...row };
        if (updatedRow.editMode !== undefined) {
          delete updatedRow.editMode;
        }
        return updatedRow;
      });
    }

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      let success;
      if (isNew) {
        delete data.id;
        success = await this.props.dispatch(createOverhead(data));
      } else {
        success = await this.props.dispatch(updateOverhead(data));
      }

      if (success && closeModal) {
        this.onClose(true);
      } else {
        // Refetch Overheadflows
        this.props.onRefresh();

        // Clear total/distribution amounts
        const refreshedControls = cloneDeep(updatedControls);
        refreshedControls.total_amount.value = null;
        refreshedControls.distributions.value = [];

        // Reset state
        this.setState({
          controls: refreshedControls,
          data: {},
          id: null,
          isNew: true,
          title: 'New Overhead',
        });
      }
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_OVERHEAD_ATTRIBUTES' });
    this.props.dispatch({ type: 'UNSET_ENTERPRISE_DISTRIBUTIONS' });

    // Replenish overhead groups and types in store
    this.props.dispatch(fetchAttributes({ type: 'overhead_groups' }));
    this.props.dispatch(fetchAttributes({ type: 'overhead_types' }));
    this.props.dispatch(fetchEnterpriseDistributions({ type: 'division' }));
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing ${data.name} overhead permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeOverhead(data.id));
      if (success) this.onClose(true);
    }
  }

  onChange(event) {
    const { controls } = this.state;
    const old_group_id = controls.group_id.value;

    this.handleChange(event);

    const { name, value } = event.target;
    switch (name) {
      case 'group_id':
        // Load another select;
        if (old_group_id !== value) {
          this.loadAttributes(value);
        }
        break;
      default:
        break;
    }
  }

  async loadAttributes(parent_id) {
    if (parent_id === '' || parent_id === '-') {
      await this.props.dispatch({ type: 'UNSET_OVERHEAD_TYPES_ATTRIBUTES' });
    } else {
      await this.props.dispatch(fetchAttributes({ type: 'overhead_types', parent_id }));
    }
  }

  onDistributionChange() {
    const { controls } = this.state;

    if (controls?.distributions?.value?.length > 0) {
      // Handle updating
      const totalValue = controls.distributions.value
        .map((distribution) =>
          distribution.distribution_amount ? parseFloat(distribution.distribution_amount) : 0,
        )
        .reduce((current, next) => current + next);

      if (controls.total_amount.value !== totalValue) {
        controls.total_amount.value = totalValue;
        this.setState({
          controls,
        });
      }
    }
  }

  render() {
    let { controls } = this.state;
    const { isNew, title } = this.state;
    const { isOpen } = this.props;
    const { overhead_groups, overhead_types, transaction_intervals } = this.props.attributes;
    const { distributions } = this.props.enterprises;
    const { responseMessage } = this.props.overheads;
    const filteredTransactionIntervals = getFilteredTransactionIntervals(transaction_intervals);

    controls = updateControlOptions(controls, 'property_id', this.props.properties.properties.rows);

    const iconName = 'clipboard-list';

    controls = updateControlOptions(controls, 'group_id', overhead_groups);

    controls.type_id.disabled = overhead_types.length === 0;
    controls = updateControlOptions(controls, 'type_id', overhead_types);

    // Add default distribution values if no values are populated
    if (controls.distributions.value === null || controls.distributions.value.length === 0) {
      controls.distributions.value = distributions;
    }

    // Check if Group field has been chosen
    const group_id = controls.group_id.value;
    let isDistributable = true;
    controls.total_amount.disabled = true;

    if (!isEmpty(group_id)) {
      const targetGroup = overhead_groups.find(({ id }) => group_id === id);
      // Disable distributions and enable total amount field for groups which do not allow distributions
      if (targetGroup?.rules?.allowDistributions === false) {
        isDistributable = false;
        controls.total_amount.disabled = false;
      }
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.property_id} />
            <small className="text-success">Leave blank for organisational transactions.</small>

            <FormInput handleChange={this.onChange} control={controls.group_id} />
            <FormInput handleChange={this.handleChange} control={controls.type_id} />
            <FormIntervalDatePicker
              handleChange={this.handleChange}
              controls={controls}
              intervals={filteredTransactionIntervals}
              intervalKey="transaction_interval_id"
              dateKey="transaction_date"
            />
            <FormInput handleChange={this.handleChange} control={controls.total_amount} />
          </Form>
          {isDistributable && (
            <OverheadDistributionsLsv
              rows={controls.distributions.value}
              totalValue={controls.total_amount.value ?? 0}
              onDistributionChange={this.onDistributionChange}
            />
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            {isNew && (
              <Button size="sm" className="mr-2" color="primary" onClick={() => this.onSave(false)}>
                Save/Add New
              </Button>
            )}
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              {isNew ? 'Save/Close' : 'Save'}
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises, overheads, properties }) => ({
  attributes,
  enterprises,
  overheads,
  properties,
});

export default connect(mapStoreToProps)(OverheadModal);
