import React from 'react';
import { connect } from 'react-redux';
import FormBase from 'jsx/components/core/form/components/FormBase';
import Icon from 'jsx/components/core/icons/Icon';
import AdminProbeTemplateToolbar from '../../components/admin/AdminProbeTemplateToolbar';
import AdminProbeTemplateLsv from '../../components/admin/AdminProbeTemplateLsv';
import AdminProbeTemplateModal from './AdminProbeTemplateModal';
import { controls as sectionControls} from '../../forms/admin/template_section';

import {
  createProbeSection,
  removeProbeSection,
  fetchProbeTemplateByTag
} from '../../actions/admin/probe';
import { type } from 'os';

class AdminProbeTemplate extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
        isValid: true,
        isOpen: false,
        data: {},
        id: null,
        isNew: true,
        title: '',
        setModal: null,
        isProbeTemplateModalOpen: false,
        showSectionEditor: false,
        controls: sectionControls,
        section: {},
        probe_tag: 'standard_probe'
    };

    this.renderSections = this.renderSections.bind(this);
    this.renderListviewRows = this.renderListviewRows.bind(this);
    this.setProbeTemplateModal = this.setProbeTemplateModal.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onAddRow = this.onAddRow.bind(this);
    this.onHandleSectionChange = this.onHandleSectionChange.bind(this);
    this.onSectionSave = this.onSectionSave.bind(this);
    this.toggleSectionClose = this.toggleSectionClose.bind(this);
    this.onRemoveSection = this.onRemoveSection.bind(this);
  }

  async componentDidMount() {
    this.props.dispatch(fetchProbeTemplateByTag(this.state.probe_tag));
  }

  onRefresh() {
    this.props.dispatch(fetchProbeTemplateByTag(this.state.probe_tag));
  }

  onAddRow(section) {
    this.setProbeTemplateModal(null, section);
  }

  setProbeTemplateModal(id, section) {
    this.setState({section, id, isProbeTemplateModalOpen: !this.state.isProbeTemplateModalOpen});
  }

  onHandleSectionChange (event) {
    const { controls } = this.state;
    controls[event.target.name].value = event.target.value;

    this.setState({controls});
  }

  async onSectionSave() {
    const { controls, isNew } = this.state;
    const { probeTemplate } = this.props.probe;

    const unsavedData = {
      caption: controls.name.value,
      version_id: probeTemplate.id,
      sequence: controls.sequence.value || 0
    };

    let success;
    if (isNew) {
      success = await this.props.dispatch(createProbeSection(unsavedData));
    } else {
      // success = await this.props.dispatch(updateProbeSection(unsavedData));
    }
    if (success) {
      this.props.dispatch(fetchProbeTemplateByTag('standard_probe'));
      this.toggleSectionClose(false);
    }
  }

  toggleSectionClose(open) {
    this.setState({showSectionEditor: open});
  }

  async onRemoveSection(id) {
    const confirmed = window.confirm('Removing Probe Section and all rows under it permanently. Continue?');
    if (confirmed) {
      await this.props.dispatch(removeProbeSection(id));
      this.props.dispatch(fetchProbeTemplateByTag(this.state.probe_tag));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderListviewRows (section) {
    const lsvRows = [];
    const type_iterations = [];

    let prevCaption;
    
    section.groups.map(group => {
      group.rows.map(row => {
        const lsvRow = {
          group_caption: (prevCaption !== group.caption ? group.caption : '')
        };
        lsvRow.id = row.id;
        lsvRow.metric_name = row.metric.name;
        lsvRow.metric_key = row.metric.key;
        lsvRow.formula = row.metric.formula;
        lsvRow.description = row.metric.description;
        lsvRow.unit = `${row.metric.unit_attributes.prefix || ''}${row.metric.unit_attributes.suffix || ''}`;
        lsvRow.attributes = row.attributes;
        lsvRow.show_benchmarks = row.show_benchmarks;
        lsvRow.isSeries = (row.thread_id ? 'Yes' : 'No');
        lsvRow.iteration_type_tag = row.iteration_type_tag;

        const tag = `${row?.attributes?.filter?.divisions[0]?.id}_${row.metric.id}`;
        if (row.iteration_type_tag === 'by_enterprise_types' && !type_iterations.includes(tag)) {
          type_iterations.push(tag);
          lsvRows.push(lsvRow);
          
          if (lsvRow.group_caption !== '') {
            lsvRow.group_caption = `${row?.attributes?.filter?.divisions[0]?.name} (All Enterprise Types)`;
          }
        }

        if (row.iteration_type_tag === 'single') lsvRows.push(lsvRow);

        prevCaption = group.caption;
      });
    });

    return (
      <AdminProbeTemplateLsv rows={lsvRows} onClick={this.setProbeTemplateModal} section={section}/>
    );
  };

  renderSections () {
    const {
      probeTemplate
    } = this.props.probe;

    if (probeTemplate.sections) {
      const sections = probeTemplate.sections.map((section, index) => (
        <React.Fragment key={index}>
          <div className="d-flex justify-content-between bg-green-20 p-1 border-bottom border-green-80">
            <div style={{fontSize: 20}} >{section.caption}</div>
            <div className="d-flex justify-content-end">
              <Icon title="Edit" className="mr-3 mt-1 text-primary" style={{fontSize: 20}} name="plus" onClick={() => this.onAddRow(section)}/>
              <Icon title="Remove" className="mr-2 mt-1 text-danger" style={{fontSize: 20}} name="trash" onClick={() => this.onRemoveSection(section.id)}/>
            </div>
          </div>

          {this.renderListviewRows(section)}
        </React.Fragment>
      ));
  
      return sections;
    }

    return (<></>);
  }

  render() {
    const {
      probeTemplate
    } = this.props.probe;

    const {
      isProbeTemplateModalOpen,
      id,
      probe_tag,
      showSectionEditor,
      controls,
      section
    } = this.state;

    return (
      <div>
        <AdminProbeTemplateModal
          isOpen={isProbeTemplateModalOpen}
          setModal={this.setProbeTemplateModal}
          id={id}
          probe_tag={probe_tag}
          section={section}
        />
        <AdminProbeTemplateToolbar
          template={probeTemplate}
          onRefresh={this.onRefresh} 
          onHandleSectionChange={this.onHandleSectionChange}
          onSectionSave={this.onSectionSave}
          toggleSectionClose={this.toggleSectionClose}
          sectionControls={controls}
          showSectionEditor={showSectionEditor}
        />
        <div>
          {this.renderSections()}
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({ analysis_periods, analysis_groups, probe }) => ({
  analysis_periods,
  analysis_groups,
  probe
});

export default connect(mapStoreToProps)(AdminProbeTemplate);
