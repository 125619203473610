import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';

import { getFilteredTransactionIntervals } from 'jsx/lib/lookupAttributes';
import FormIntervalDatePicker from 'jsx/components/core/form/components/FormIntervalDatePicker';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import LabourDistributionsLsv from '../components/LabourDistributionsLsv';

import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls as labourControls } from '../forms/labour';

import { fetchAttributes } from '../actions/attributes';
import { fetchLabour, updateLabour, createLabour, removeLabour } from '../actions/labours';
import { fetchEnterpriseDistributions } from '../actions/enterprises';

class LabourModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(labourControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Labour',
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onDistributionChange = this.onDistributionChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const controls = initControls(cloneDeep(labourControls));
      controls.distributions.value = [];

      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Labour',
      };

      this.props.dispatch(fetchAttributes({ type: 'labour_categories' }));
      this.props.dispatch(fetchEnterpriseDistributions({ category: 'labour', type: 'division' }));

      if (this.props.id) {
        const { id } = this.props;
        updatedState = {
          ...updatedState,
          id,
          isNew: false,
          title: 'Edit Labour',
        };

        this.props.dispatch(fetchLabour(id)).then((data) => {
          this.setState({
            data,
            controls: updateControls(controls, data),
          });
        });
      }

      this.setState(updatedState);
    }
  }

  async onSave() {
    const { data, controls, isNew } = this.state;

    const unsavedData = saveControls(controls, data);

    // Set default value for distributions.
    unsavedData.distributions = [];

    /**
     * Only add distributions if sum is greater than 0.
     * This is to ensure distributions are not appended to labour categories which do not require distributions.
     */
    const sum = controls.distributions.value
      .map(({ weeks_count }) => (weeks_count ? parseFloat(weeks_count) : 0))
      .reduce((accumulator, currentValue) => accumulator + currentValue);

    if (sum > 0) {
      unsavedData.distributions = controls.distributions.value;
      unsavedData.distributions.map((row) => {
        const updatedRow = { ...row };
        if (updatedRow.editMode !== undefined) {
          delete updatedRow.editMode;
        }
        return updatedRow;
      });
    }

    const { isValid, updatedControls } = await validateFormFieldControls(unsavedData, controls);
    if (isValid) {
      let success;
      if (isNew) {
        delete unsavedData.id;
        success = await this.props.dispatch(createLabour(unsavedData));
      } else {
        success = await this.props.dispatch(updateLabour(unsavedData));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_LABOUR_ATTRIBUTES' });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing ${data.name} labour permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeLabour(data.id));
      if (success) this.onClose(true);
    }
  }

  onDistributionChange() {
    const { controls } = this.state;

    if (controls?.distributions?.value?.length > 0) {
      // Handle updating
      const totalValue = controls.distributions.value
        .map(({ weeks_count }) => (weeks_count ? parseFloat(weeks_count) : 0))
        .reduce((accumulator, currentValue) => accumulator + currentValue)
        .toFixed(2);

      if (controls.total_weeks_count.value !== totalValue) {
        controls.total_weeks_count.value = totalValue;
        this.setState({
          controls,
        });
      }
    }
  }

  onAttributeChange = (event) => {
    const { value } = event.target;
    const { labour_categories } = this.props.attributes;

    const currentAttributeId = this.state.controls.attribute_id.value;
    const currentAttribute = labour_categories.find(({ id }) => id === currentAttributeId);
    const updateAttribute = labour_categories.find(({ id }) => id === value);

    const { value: distributions } = this.state.controls.distributions;
    const untouchedDistributions =
      distributions.map(({ weeks_count }) => weeks_count ?? false).filter(Boolean).length === 0;

    if (
      currentAttribute?.rules?.allowDistributions === false &&
      updateAttribute?.rules?.allowDistributions === true &&
      untouchedDistributions
    ) {
      const { controls } = this.state;
      controls.total_weeks_count.value = 0;
      const defaultDistributions = controls.distributions.value.map((distribution) => ({
        ...distribution,
        weeks_count: null,
      }));
      controls.distributions.value = defaultDistributions;
      this.setState({ controls });
    }

    this.handleChange(event);
  };

  render() {
    let { controls } = this.state;
    const { title, isNew } = this.state;
    const { isOpen } = this.props;
    const { labour_categories, transaction_intervals } = this.props.attributes;
    const { distributions } = this.props.enterprises;
    const { responseMessage } = this.props.labours;
    const { properties } = this.props.properties;
    const filteredTransactionIntervals = getFilteredTransactionIntervals(transaction_intervals);

    const iconName = 'clipboard-list';

    controls = updateControlOptions(controls, 'attribute_id', labour_categories);
    controls = updateControlOptions(controls, 'is_paid', controls.is_paid.options);
    controls = updateControlOptions(controls, 'property_id', properties?.rows);

    // Add default distribution values if no values are populated
    if (controls.distributions.value === null || controls.distributions.value.length === 0)
      controls.distributions.value = distributions;

    // Determine if distributions are to be displayed
    const category_id = controls.attribute_id.value;
    const category = labour_categories.find(({ id }) => id === category_id);
    const showDistributions = category_id && category?.rules?.allowDistributions === true;

    // Enable/Disable total weeks count input field
    controls.total_weeks_count.disabled = showDistributions;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.property_id} />
            <FormInput handleChange={this.onAttributeChange} control={controls.attribute_id} />
            <FormInput handleChange={this.handleChange} control={controls.is_paid} />
            <FormIntervalDatePicker
              handleChange={this.handleChange}
              controls={controls}
              intervals={filteredTransactionIntervals}
              intervalKey="transaction_interval_id"
              dateKey="from_date"
            />
            <FormInput handleChange={this.handleChange} control={controls.total_weeks_count} />
          </Form>
          {showDistributions && (
            <LabourDistributionsLsv
              rows={controls.distributions.value}
              onDistributionChange={this.onDistributionChange}
            />
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises, labours, properties }) => ({
  attributes,
  enterprises,
  labours,
  properties,
});

export default connect(mapStoreToProps)(LabourModal);
