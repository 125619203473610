/* eslint-disable no-param-reassign, no-plusplus */
import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Nav, TabContent, TabPane } from 'reactstrap';
import FormBase from 'jsx/components/core/form/components/FormBase';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { getFilteredTransactionIntervals } from 'jsx/lib/lookupAttributes';
import FormTab from '../../../core/form/components/FormTab';
import PageTitle from '../../../core/form/components/PageTitle';
import OtherBusinessToolbar from '../components/otherBusiness/OtherBusinessToolbar';
import GenericLsv from '../../../core/form/components/GenericLsv';
import OtherBusinessIncomeModal from './OtherBusinessIncomeModal';
import { fetchAttribute, fetchAttributes } from '../actions/attributes';
import { fetchEnterpriseRanges } from '../actions/enterprises';
import { fetchDirectCosts } from '../actions/directCosts';
import { fetchBusinessIncomes } from '../actions/otherBusiness';
import { controls as controlsDirectCost } from '../forms/directCosts';
import { controls as controlsBusinessIncome } from '../forms/otherBusinessIncome';
import DirectCostModal from './DirectCostModal';

class OtherBusinessEnterprises extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      modalType: null,
      isAnimalClassValueModalOpen: false,
      isModalOpen: false,
      isDirectCostModalOpen: false,
      isBusinessIncomeModalOpen: false,
      modalData: null,
      division_id: null,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.setBusinessIncomeModal = this.setBusinessIncomeModal.bind(this);
    this.setDirectCostModal = this.setDirectCostModal.bind(this);
    this.addDistributionControls = this.addDistributionControls.bind(this);
  }

  componentDidMount() {
    this.props
      .dispatch(fetchAttribute({ type: 'divisions', tag: 'other_business' }))
      .then((data) => {
        const division_id = data.id;
        this.setState({ division_id });
      });
  }

  componentDidUpdate() {
    const { isActive, forceRefresh, setRefresh } = this.props;

    if (isActive && forceRefresh && setRefresh) {
      this.onRefresh();
      if (setRefresh) setRefresh(false);
    }
  }

  addDistributionControls = (controls, rows) => {
    const { distribution_default } = controls;
    const updatedControls = cloneDeep(controls);

    // Check rows exist
    if (rows.rows?.length > 0) {
      // Check distributions exist and are populated
      rows.rows.forEach((row) => {
        if (row.distributions?.length > 0) {
          // Iterate through each distribution
          row.distributions.forEach((distribution, idx) => {
            // Add distribution control
            const enterpriseName = `${distribution.enterprise.name
              .toString()
              .toLowerCase()}_distribution`;

            updatedControls[enterpriseName] = {
              ...distribution_default,
              caption: `${distribution.enterprise.name} (%)`,
              fieldName: `distributions.${(idx++).toString()}.distribution_pcnt`,
              formattingRules: {
                asPercentage: true,
                includeDecimals: true,
                includePercentageSign: true,
              },
              name: `${enterpriseName}`,
              showInListview: true,
            };
          });
        }
      });
    }

    return updatedControls;
  };

  async toggleTab(tab) {
    let { modalType } = this.state;
    const { division_id } = this.state;
    const { selectedRanges, selectedProperties } = this.props.enterprises;

    const { dispatch } = this.props;

    dispatch(fetchEnterpriseRanges());

    switch (parseInt(tab)) {
      case 8: {
        await dispatch(fetchAttributes({ type: 'cost_categories', parent_id: division_id }));
        dispatch(
          fetchDirectCosts({
            division_id,
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'direct';
        break;
      }
      case 9: {
        dispatch(
          fetchBusinessIncomes({
            division_id,
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'business_income';
        break;
      }

      default:
        break;
    }

    const { activeOtherBusinessTab } = this.props.other_business;

    if (activeOtherBusinessTab !== tab) {
      dispatch({
        type: 'SET_BUSINESS_OTHER_BUSINESS_ACTIVE_TAB',
        payload: tab,
      });
    }

    this.setState({
      modalType,
    });
  }

  onRefresh() {
    const { activeOtherBusinessTab } = this.props.other_business;
    const tab_id = activeOtherBusinessTab;
    if (tab_id) this.toggleTab(tab_id);
  }

  setModalOptions(type) {
    const { cost_types, transaction_intervals, cost_categories } = this.props.attributes;
    const { direct_costs } = this.props.direct_costs;
    const { business_incomes } = this.props.other_business;
    const filteredTransactionIntervals = getFilteredTransactionIntervals(transaction_intervals);
    let options;

    switch (type) {
      case 'direct': {
        const updatedControls = this.addDistributionControls(controlsDirectCost, direct_costs);
        options = {
          title: 'Direct Cost',
          iconName: 'handshake',
          controls: updatedControls,
          options: {
            type_id: cost_types,
            transaction_interval_id: filteredTransactionIntervals,
          },
        };
        break;
      }
      default: {
        const updatedControls = this.addDistributionControls(
          controlsBusinessIncome,
          business_incomes,
        );
        options = {
          title: 'Income',
          iconName: 'handshake',
          controls: updatedControls,
          options: {
            category_id: cost_categories,
            transaction_interval_id: filteredTransactionIntervals,
          },
        };
        break;
      }
    }

    options.title = `Other Business ${options.title}`;
    return options;
  }

  setBusinessIncomeModal(isBusinessIncomeModalOpen, id = null) {
    this.setState({
      isBusinessIncomeModalOpen,
      id,
    });
  }

  setDirectCostModal(isDirectCostModalOpen, id = null) {
    this.setState({
      isDirectCostModalOpen,
      id,
    });
  }

  render() {
    const { id, modalType, isDirectCostModalOpen, isBusinessIncomeModalOpen, division_id } =
      this.state;

    const { activeOtherBusinessTab, business_incomes, responseMessage } = this.props.other_business;

    const { direct_costs } = this.props.direct_costs;
    const modalOptions = this.setModalOptions(modalType);
    const title = 'Other Business Enterprises';
    const iconName = 'handshake';
    return (
      <div className="p-0 h-100">
        <PageTitle title={title} iconName={iconName} />
        <OtherBusinessToolbar
          setModal={this.setModal}
          onRefresh={this.onRefresh}
          setDirectCostModal={this.setDirectCostModal}
          setBusinessIncomeModal={this.setBusinessIncomeModal}
        />
        <DirectCostModal
          division_id={division_id}
          id={id}
          isNew={id === null}
          isOpen={isDirectCostModalOpen}
          onRefresh={this.onRefresh}
          setModal={this.setDirectCostModal}
        />
        <OtherBusinessIncomeModal
          onRefresh={this.onRefresh}
          setModal={this.setBusinessIncomeModal}
          id={id}
          isOpen={isBusinessIncomeModalOpen}
          isNew={id === null}
          division_id={division_id}
          responseMessage={responseMessage}
        />

        <Nav tabs className="mt-2">
          <FormTab
            caption="Incomes"
            tabId="9"
            activeTab={activeOtherBusinessTab}
            toggle={this.toggleTab}
            tabTag="business_incomes"
          />
          <FormTab
            caption="Direct Costs"
            tabId="8"
            activeTab={activeOtherBusinessTab}
            toggle={this.toggleTab}
            tabTag="costs"
          />
        </Nav>

        <TabContent activeTab={activeOtherBusinessTab}>
          <TabPane tabId="8" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No direct costs found"
              onClick={(directCostId) => {
                this.setDirectCostModal(true, directCostId);
              }}
              rows={direct_costs}
              totalFormattingRules={{
                includeDollarSign: true,
                includeCommas: true,
              }}
            />
          </TabPane>
          <TabPane tabId="9" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No other incomes found"
              onClick={(otherIncomeId) => {
                this.setBusinessIncomeModal(true, otherIncomeId);
              }}
              rows={business_incomes}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises, direct_costs, other_business }) => ({
  attributes,
  enterprises,
  direct_costs,
  other_business,
});

export default connect(mapStoreToProps)(withContainerError(OtherBusinessEnterprises));
